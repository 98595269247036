import PropTypes from "prop-types"
import React from "react"
import { data } from "../lib/data"
import { adHelper } from "../lib/ad_helper"
import { navigate } from 'gatsby';

export const TopBannerItem = ({ property }) => {
  const agent = adHelper.parseAgent(property.description)
  return (
    <div className="item top-banner-item">
      <div
        className="top-video-banner-holder w-100 h-100"
        style={{ background: "#000" }}
      >
        {/* {prop.videos?.video?.link?.href ? (
          <video width="1280" className="top-banner-video" loop>
            <source src={prop.videos.video.link.href} type="video/youtube" />
          </video>
        ) : ( */}
          <img
            src={adHelper.firstLargePictureUrl(property)}
            alt={property.title}
            className="object-fit-cover w-100 h-100"
          />
        {/* )} */}
      </div>
      <div className="custom-carousel-caption-holder clearfix position-absolute w-100 h-100 d-flex flex-wrap align-items-end">
        <div className="container clearfix">
          <div className="custom-carousel-caption-1 clearfix position-relative">
            <p className="custom-carousel-caption-subtitle text-uppercase mb-3 text-white">
              {data.userProfile.userTagline.split(" | ")[0]}
            </p>
            <h3 className="custom-carousel-caption-title-1 text-white mb-4">
              {adHelper.lineBreaks(
                data.userProfile.userTagline.split(" | ")[1]
              )}
            </h3>
            <a
              href={adHelper.propertyLink(property)}
              className="text-decoration-none text-white d-inline-flex main-banner-caption-link align-items-center flex-wrap"
            >
              View Property
              <img
                src="/images/long-arrow-right-white.png"
                className="ml-2 w-auto"
                alt="right"
              />
            </a>
            {/* {prop.videos?.video?.link?.href ? (
              <div className="mainBannerPlayIcon">
                <a className="home-top-video-player-btn">
                  <span>
                    <i className="fas fa-play"></i>
                  </span>
                </a>
              </div>
            ) : null} */}
          </div>
          <div className="custom-carousel-caption-2 clearfix position-relative d-flex flex-wrap align-items-start">
            <div className="prop-holder-pic clearfix h-100">
              <img
                src={`/images/agents/small/${agent.name}.jpg`}
                alt="prop-holder"
                className="object-fit-cover w-100 h-100"
              />
            </div>
            <div onClick={() => navigate(adHelper.agentUrl(agent.name))} className="prop-holder-content-holder1 p-4 clearfix bg-white d-flex flex-wrap align-items-center h-100">
              <div className="prop-holder-content-inner clearfix">
                <h5 className="prop-holder-title-1 text-dark">{agent.name}</h5>
                <p className="prop-holder-title-2 text-uppercase text-dark">
                  {agent.role}
                </p>
              </div>
              <a href={adHelper.agentUrl(agent.name)} className="mr-0 ml-auto prop-holder-link">
                <img src="/images/long-arrow-right-black.png" alt="arrow" />
              </a>
            </div>
            <div onClick={() => navigate(adHelper.propertyLink(property))} className="prop-holder-content-holder1 prop-holder-content-holder2 p-4 clearfix bg-green-1 d-none d-lg-flex flex-wrap align-items-center h-100">
              <div className="prop-holder-content-inner clearfix">
                <h5 className="prop-holder-title-1 text-white">{property.title.length > 27 ? `${property.title.substring(0,27)}... ` : property.title}</h5>
                <p className="prop-holder-title-2 text-uppercase text-white">
                  title
                </p>
              </div>
              <div className="prop-holder-content-inner clearfix">
                <h5 className="prop-holder-title-1 text-white">{agent.area}</h5>
                <p className="prop-holder-title-2 text-uppercase text-white">
                  location
                </p>
              </div>
              <a href={adHelper.propertyLink(property)} className="mr-0 ml-auto prop-holder-link">
                <img src="/images/long-arrow-right-white.png" alt="arrow" />
              </a>
            </div>
          </div>
          <div className="custom-carousel-caption-3 clearfix position-absolute">
            <ul className="banner-prop-social-icons-holder clearfix list-unstyled">
              <li>
                <a href={agent.facebookUrl} target="_blank" rel="noreferrer" className="text-decoration-none text-white banner-prop-social-icons-link">
                  <i className="fab fa-facebook-square text-white"></i>
                </a>
              </li>
              <li>
                <a href={agent.youtubeUrl} target="_blank" rel="noreferrer" className="text-decoration-none text-white banner-prop-social-icons-link">
                  <i className="fab fa-youtube text-white"></i>
                </a>
              </li>
              <li>
                <a href={agent.instagramUrl} target="_blank" rel="noreferrer" className="text-decoration-none text-white banner-prop-social-icons-link">
                  <i className="fab fa-instagram text-white"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

TopBannerItem.propTypes = {
  property: PropTypes.shape({
    description: PropTypes.any,
    id: PropTypes.any,
    title: PropTypes.shape({
      length: PropTypes.number,
      substring: PropTypes.func
    })
  })
}
