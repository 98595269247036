import React from "react"
import { adHelper } from "../lib/ad_helper"
import { TopBannerItem } from "./TopBannerItem"

export const Banner = () => (
  <div className="banner-section clearfix">
    <div className="owl-carousel owl-theme owl-carousel-home">
      {adHelper.featuredAds().map(f => (
        <TopBannerItem key={f.id} property={f} />
      ))}
    </div>
  </div>
)
