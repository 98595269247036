import React from "react"
import { Banner } from "../components/Banner"
// import { Achieves } from "../components/Achieves"
// import { Testimonials } from "../components/Testimonials"
// import { TopFeatured } from "../components/TopFeatured"
// import { PropertySlider } from "../components/PropertySlider"
// import { LogoSlider } from "../components/LogoSlider"
import { Layout } from "../components/Layout"

export default function Home() {
  return (
    <Layout className="home-page">
      <Banner />
      {/* <IntroServices /> */}
      {/* <Achieves /> */}
      {/* <Testimonials /> */}
      {/* <TopFeatured /> */}
      {/* <PropertySlider /> */}
      {/* <LogoSlider /> */}
    </Layout>
  )
}
